import api from "./api";
import storage from "../utils/Storage";
import { message } from "antd";

class RoomAPI {
    /**
     *
     * @returns {Object}
     */
    createRoom = async () => {
        console.log("Create Room!!");
        try {
            let response = await api.post("/api/room", null, {
                headers: {
                    Authorization: "Bearer " + storage.getUser().token,
                },
            });

            storage.setRoom(response.data);
            return response.data;
        } catch (error) {
            if (error.response.status == 401) {
                console.log("createRoom: ", error.response.status);
                storage.setAuth(false);
            }
        }
    };

    joinRoom = async (providerUuid) => {
        try {
            let response = await api.post(
                `/api/joinRoom/${providerUuid}`,
                null,
                {
                    headers: {
                        Authorization: "Bearer " + storage.getUser().token,
                    },
                }
            );

            storage.setRoom(response.data);

            return response.data;
        } catch (error) {
            if (error.response.status == 401) {
                console.log("joinRoom: ", error.response.status);
                storage.setAuth(false);
            } else if (error.response.status == 404) {
                console.log("sala ocupada!");
                return { busy: true };
            }

            console.log("Error - joinRoom: ", error);
            return { busy: true };
        }
    };

    /**
     * take all available rooms.
     *
     * @returns {Object}
     */
    getAvailableRooms = async () => {
        if (!storage.getUser()) {
            return { error: true };
        }

        try {
            let response = await api.get("/api/getAvailableRooms", {
                headers: {
                    Authorization: "Bearer " + storage.getUser().token,
                },
            });

            storage.setRoom(response.data);
            return { error: false };
        } catch (error) {
            if (error.response.status == 401) {
                console.log("getAvailableRooms: ", error.response.status);
                storage.setAuth(false);
            }
            return { error: true };
        }
    };

    closeRoom = async () => {
        try {
            const response = await api.post(
                `/api/releaseRoom/${storage.getUser().user.uuid}`,
                null,
                {
                    headers: {
                        Authorization: "Bearer " + storage.getUser().token,
                    },
                }
            );
            console.log("realeseRoom okk");
            storage.removeRoom();
        } catch (error) {
            if (error.response.status == 401) {
                console.log("closeRoom: ", error.response.status);
                storage.setAuth(false);
            }
        }
    };
}

export default new RoomAPI();
