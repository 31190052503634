import React from "react";
import "./index.css";
import { V4Hlogo } from "../../assets";
require("dotenv/config");

export default function Mobile({ history }) {
    let token;
    let sessionId;

    try {
        token = history.location.state.token;
        sessionId = history.location.state.sessionId;
    } catch {
        console.log();
    }

    const iOS = () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    };

    // Plataform IOS
    const iOSRender = () => {
        return (
            <div id="bodyMobile">
                <div className="containerMobile">
                    <div className="headerMobile">
                        <img src={require("../../assets/icons/logo2.png")}/>
                    </div>
                    <div className="tutorialMobile">
                        <p>
                            Para acessar a videochamada, você precisa 
                            instalar o aplicativo V4H.
                        </p>
                    </div>
                    <div className="buttonMobile">
                        <a
                            className="linkConferenceIOS"
                            href="https://apps.apple.com/br/app/id1504981384"
                        >
                            Instalar V4H
                        </a>
                    </div>
                    <div>
                        <p>
                            Com o V4H instalado, você poderá acessar a 
                            videochamada clicando no botão abaixo.
                        </p>
                    </div>
                    <div className="buttonMobile">
                        <a
                            className="linkConferenceIOS"
                            href={`conf.v4h.cloud://${process.env.REACT_APP_V4H_URL}/${sessionId}?jwt=${token}`}
                        >
                            Ir para videochamada
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    // Plataform Android
    const AndroidRender = () => {
        return (
            <div id="bodyMobile">
                <div className="containerMobile">
                    <div className="headerMobile">
                        <V4Hlogo style={{width:"180px"}}/>
                    </div>
                    <div className="tutorialMobile">
                        <p>
                            Para acessar a videochamada, você precisa 
                            instalar o aplicativo V4H.
                        </p>
                    </div>
                    <div className="buttonMobile">
                        <a
                            className="linkConferenceAndroid"
                            href="https://play.google.com/store/apps/details?id=lavid.conf.v4h.cloud"
                        >
                            Instalar V4H
                        </a>
                    </div>
                    <div>
                        <p>
                            Com o V4H instalado, 
                            você poderá acessar a videochamada.                            
                        </p>
                    </div>
                    <div className="buttonMobile">
                        <a
                            className="linkConferenceAndroid"
                            href={`https://${process.env.REACT_APP_V4H_URL}/${sessionId}?jwt=${token}`}
                        >
                            Ir para videochamada
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    return iOS() ? iOSRender() : AndroidRender();
}

/***
 * IOS Model
conf.v4h.cloud://aws.v4h.cloud/91144d839a0340e8aa88a8523460045a?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnsibmFtZSI6Ikx1Y2FzIn19LCJhdWQiOiJ2NGhfYXBwIiwiaXNzIjoidjRoX2FwcCIsInN1YiI6InY0aC1uZXQiLCJyb29tIjoiKiIsImlhdCI6MTU4NTg2MjE0MH0.dHqZhUU9EG12AtLZcQbAJQ1kZCWe6tDSZ_pM1zFbWTA
 */

/**
  * Android Model
https://conf.v4h.cloud/159d6473f48142d8ab76695dd429accf?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnsibmFtZSI6Ikx1Y2FzIn19LCJhdWQiOiJ2NGhfYXBwIiwiaXNzIjoidjRoX2FwcCIsInN1YiI6InY0aC1uZXQiLCJyb29tIjoiKiIsImlhdCI6MTU4NTk0Mzc5Nn0.x_GNXaUgbGvg3nlpdVPF0eXMIAdVY1N_uL_oOoIK2LI
  */
