import React from "react";
import Routes from "./routes";
import "./App.css";
import "antd/dist/antd.css";
import { UserAPI } from "./services";
require("dotenv/config");

function App() {
    return <Routes />;
}

// disconnect the user from the application.
document.addEventListener("beforeunload", UserAPI.logout);

document.title = process.env.REACT_APP_TITLE;
document.description = process.env.REACT_APP_DESCRIPTION;

let meta = document.createElement("meta");
meta.setAttribute("property", "og:image");
meta.setAttribute("itemprop", "image");
meta.setAttribute("content", process.env.REACT_APP_IMAGE);

document.head.append(meta);

export default App;
