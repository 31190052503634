import React from "react";
import "./MobileError.css";
import { UserAPI } from "../../services";

export default function MobileError({ history }) {

    const handlerBack = () => {
        UserAPI.logout();
        history.push("/");
    }

    return (
        <div id="containerMobileError">
            <div className="textMobileError">
                Não é possível acessar a plataforma como teleconsultor através de smartphones.
                Para ter acesso a aplicação use um computador.
            </div>
            <div className="backMobileError" onClick={handlerBack}>
                Voltar
            </div>
        </div>
    );
}
