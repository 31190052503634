import React from "react";
import { Table, Button, Tag, Empty } from "antd";
import { Icon } from "@iconify/react";
import userNurse from "@iconify/icons-fa-solid/user-nurse";
import videoCamera from "@iconify/icons-fa/video-camera";
import RoomAPI from "../../services/RoomAPI";
import UserAPI from "../../services/UserAPI";
import storage from "../../utils/Storage";
import "./List.css";

export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      providers: null,
      availableProviders: null,
      loadingProviders: true,
      findProviders: true
    };

    console.log(this.state);

    this.columns = [
      {
        title: "",
        dataIndex: "avatar_url",
        key: "avatar_url",
        align: "center",
        render: (icon) => {
          if (icon) {
            return (
              <div
                className="iconRequester"
                style={{
                  backgroundImage:
                    "url(" +
                    `${process.env.REACT_APP_BACKEND_URL}/public/${icon}` +
                    ")",
                }}
              ></div>
            );
          } else {
            return (
              <div className="iconAvatar">
                <Icon icon={userNurse} />
              </div>
            );
          }
        },
      },
      {
        title: "Consultor",
        dataIndex: "requester",
        key: "requester",
        ellipsis: true,
      },
      {
        title: "Nome ",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        align: "center",
        render: (status) => {
          let color = "green";
          let text = "Disponível";
          if (status === "OFFLINE") {
            color = "#CCCC";
            text = "Indisponível";
          } else if (status === "BUSY") {
            color = "red";
            text = "Ocupado";
          } else if (status === "PAUSED") {
            color = "blue";
            text = "Pausado";
          }
          return (
            <span>
              <Tag className="status" color={color} key={status}>
                {text}
              </Tag>
            </span>
          );
        },
      },
      // {
      //   title: "Action",
      //   key: "action",
      //   align: "center",
      //   render: (text, record) => (
      //     <div style={{ display: "flex", justifyContent: "center" }}>
      //       {record.status == "AVAILABLE" ? (
      //         <Button
      //           className="requestButton"
      //           onClick={() =>
      //             this.handlerJoinConference(record.uuid)
      //           }
      //         >
      //           <Icon icon={videoCamera} />
      //           <strong className="text">
      //             Solicitar{" "}
      //           </strong>{" "}
      //           <div className="consultoriaButton">
      //             Consultoria
      //           </div>
      //         </Button>
      //       ) : (
      //         []
      //       )}
      //     </div>
      //   ),
      // },
    ];
  }

  componentDidMount = async () => {
    this.authenticated();

    try {
      // avoid problems when exiting the app.
      this.verifyRooms();
    } catch {
      console.log('CATCH VEIFY ROOM')
      return;
    }
  };

  verifyRooms = async () => {
    console.log('VERIFY ROOM')
    let { error } = await UserAPI.getProviders();

    if (error) {
      return this.authenticated();
    }

    this.processingData();
    
    if (this.props.waitingRoomEnabled && this.state.availableProviders.length) {
      const availableProviders = this.state.availableProviders.filter((prv) => prv.status === 'AVAILABLE');
      console.log(this.state.availableProviders)
      console.log(availableProviders)
      if (availableProviders.length > 0) {
        var provider = availableProviders[Math.floor(Math.random()*this.state.availableProviders.length)];
        if (provider) {
          console.log('START HANDLER TO JOIN ROOM')
          console.log(provider)
          setTimeout(() => this.handlerJoinConference(provider.uuid), 5000);
          this.setState({ loadingProviders: false });
        }
        
      }      
    }

    // constant table update.
    storage.getAuth() &&
      this.state.loadingProviders &&
      setTimeout(() => this.verifyRooms(), 2000);
  };

  processingData = () => {
    let providers = storage.getProviders().users;
    let providersList = [];
    let busyProvider = [];
    let pausedProvider = [];
    let offlineProvider = [];

    providers.map((element, index) => {
      let data = {
        key: index,
        name: element.firstName + " " + element.lastName,
        requester: `Teleconsultor ${index}`,
        status: element.status,
        uuid: element.uuid,
        avatar_url: element.avatar_url,
      };

      if (element.status == "AVAILABLE") {
        providersList.push(data);
      } else if (element.status == "BUSY") {
        busyProvider.push(data);
      } else if (element.status == "PAUSED") {
        pausedProvider.push(data);
      } else {
        offlineProvider.push(data);
      }
    });

    this.setState({ availableProviders: providersList });

    providersList.push(...pausedProvider);
    providersList.push(...busyProvider);
    providersList.push(...offlineProvider);

    this.setState({ providers: providersList });
  };

  authenticated = () => {
    if (storage.getAuth()) {
      return;
    }
    storage.removeAll();
    this.props.history.push("/login/request");
  };

  handlerJoinConference = async (providerUuid) => {
    console.log("handlerJoinConference", providerUuid);
    const { busy } = await RoomAPI.joinRoom(providerUuid).catch(() => {
      console.log('PROBLEM TO JOIN ROOM')
      this.setState({ loadingProviders: true });
    });

    if (busy) {
      this.setState({ loadingProviders: true });
      return;
    }

    this.props.history.push({ pathname: "/startConf", libras: this.props.libras, callback: this.props.callback });
  };

  handlerLogout = async () => {
    await UserAPI.logout();
    this.props.history.push("/");
  };

  render() {
    const emptyData = (
      <Empty description={false} image={false}>
        <div style={{ fontSize: "25px" }}>
          Nenhum teleconsultor disponível no momento.
        </div>
      </Empty>
    );

    return (
        <Table
          className="requesterTable"
          style={{ width: "100%" }}
          columns={this.columns}
          dataSource={this.state.providers}
          pagination={false}
          locale={{
            emptyText: emptyData,
          }}
        />
    );
  }
}
