import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Login from "./pages/login/Login";
import PasswordRequest from "./pages/passrequest/PasswordRequest";
import Register from "./pages/register/Register";
import RequesterPage from "./pages/requester/Requester";
import ConsultantPage from "./pages/consultant/Consultant";
import StartConfPage from "./pages/room/StartConf";
import MobilePage from "./pages/mobile";
import Storage from "./utils/Storage";

const PrivateRoute = ({ component: Component, ...rest }) => {
    console.log(Storage.getAuth());
    return (
        <Route
            {...rest}
            render={(props) =>
                Storage.getAuth() == true ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: `/login${rest.path}`,
                                state: { from: props.location },
                            }}
                        />
                    )
            }
        />
    );
};

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/**********/}
                {/* public */}
                {/**********/}

                {/* Login */}
                <Route path="/" exact component={Login} />
                <Route path="/login/:page" component={Login} />
                <Route path="/mobile" component={MobilePage} />

                {/*Request Password*/}
                <Route path="/passwordrequest" component={PasswordRequest} />

                {/* Register */}
                <Route path="/register" component={Register} />

                {/***********/}
                {/* private */}
                {/***********/}

                <PrivateRoute path="/request" component={RequesterPage} />
                <PrivateRoute path="/consultant" component={ConsultantPage} />
                <PrivateRoute path="/startConf" component={StartConfPage} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
