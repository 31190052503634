import React from "react";
import storage from "../../utils/Storage";
import RoomAPI from "../../services/RoomAPI";
import Mobile from "../../utils/MobileRedirect";
import WiseApi from 'wise-api';

require("dotenv/config");

export default class StartConf extends React.Component {
    constructor(props) {
        super(props);

        const { callback, libras } = (props.location) || {};

        this._NumberOfParticipants = true;
        this.user = storage.getUser().user;

        this.state = {
          callback,
          libras
        };

        console.log(props);
    }

    componentDidMount = () => {
        let room = storage.getRoom();

        // check if it is a mobile device.
        Mobile.start(this.props.history, room.auth, room.sessionId);

        document.addEventListener("unload", this._endTheConference);

        console.log(room);

        // Open jitsi meet(conference).
        if (this.user.userType === "PROVIDER") {
          this._openJitsi(room.sessionId, room.auth, document.getElementById("meet"), "100%", "100%", true);
        } else {
          this._openJitsi(room.sessionId, room.auth, document.getElementById("meet"), "100%", "100%", false);
        }
    };

    /**
     * Open Jitsi Meet
     * 
     * @param {string} sessionId
     * @param {string} jwt
     * @param {Node} parentNode
     * @param {string} width
     * @param {string} height
     *  
     **/
    _openJitsi = async (sessionId, jwt, parentNode, width, height, moderator) => {

      this.wiseApi = await WiseApi({baseUrl: 'https://session-manager.homolog.v4h.cloud/api/v1', domain: 'conf.homolog.v4h.cloud' });

      this.wiseApi.session.startConference(sessionId, {
        parentNode: parentNode,
        width: width,
        height: height,
        restricted: moderator,
        moderator: moderator,
        logo: 'https://minio.homolog.v4h.cloud/procon/procon-jp-logo.png',
        userInfo: { displayName: this.user.firstName + " " + this.user.lastName },
        buttons: [
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          "fullscreen",
          "fodeviceselection",
          "hangup",
          "profile",
          "chat",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          "stats",
          "tileview",
          "videobackgroundblur",
          "help",
          "libras"
        ],
        onClose: () => {
            document.getElementById("meet").innerHTML = "";
            this._endTheConference();
        },
        onParticipantLeft: () => {
          document.getElementById("meet").innerHTML = "";
          this._endTheConference();
        },
        onLoad: () => {
          console.log('TESTE')
          if (this.props.libras || this.state.libras) {
            this.wiseApi.session.toogleLibras();
          }
        }
      });

        // width = width || 640;
        // height = height || 480;
        // const domain = process.env.REACT_APP_V4H_URL;
        // const options = {
        //     roomName: sessionId,
        //     width: width,
        //     height: height,
        //     noSsl: false,
        //     parentNode: parentNode,
        //     jwt: jwt,
        //     interfaceConfigOverwrite: {
        //         TOOLBAR_BUTTONS: [
        //             "microphone",
        //             "camera",
        //             "closedcaptions",
        //             "desktop",
        //             "fullscreen",
        //             "fodeviceselection",
        //             "hangup",
        //             "profile",
        //             "chat",
        //             "settings",
        //             "raisehand",
        //             "videoquality",
        //             "filmstrip",
        //             "stats",
        //             "tileview",
        //             "videobackgroundblur",
        //             "help",
        //         ]
        //     }
        // };

        // try {
        //     this.jApi = new window.JitsiMeetExternalAPI(domain, options);
        // } catch {
        //     return console.log("Erro ao acessar o serviço da conf.");
        // }

        // this.jApi.executeCommand("displayName", this.user.firstName + " " + this.user.lastName);

        // const { avatar_url } = this.user;
        // if (avatar_url) {
        //     this.jApi.executeCommand("avatarUrl", avatar_url);
        // }

        // this.jApi.addEventListener("readyToClose", () => {
        //     document.getElementById("meet").innerHTML = "";
        //     this._endTheConference();
        // });

        // this.jApi.addEventListener("participantLeft", () => {
        //     document.getElementById("meet").innerHTML = "";
        //     this._endTheConference();
        // });

        // this.user.userType == "REQUESTER" && this._NumberOfParticipants && this._participantsConf();
    };

    _participantsConf = () => {
        setTimeout(() => {
            console.log("number participants: ", this.wiseApi.session.getNumberOfParticipants());
            if (this.wiseApi.session.getNumberOfParticipants() == 1) {
                this._endTheConference();
            } else if (this.wiseApi.session.getNumberOfParticipants() == 2) {
                this._NumberOfParticipants = false;
            }

            this._NumberOfParticipants && this._participantsConf();
        }, 1500);
    }

    _endTheConference = () => {
        this._NumberOfParticipants = false;

        console.log(this.props);
        console.log(this.state);

        if (this.user.userType === "PROVIDER") {
            RoomAPI.closeRoom();
            this.props.changeState();
            if (this.props.callback) window.location.replace(atob(this.props.callback));
        } else {
            if (this.props.callback || this.state.callback) return window.location.replace(atob(this.props.callback ?? this.state.callback));
            this.props.history.push("/request");
        }
    };

    renderConf = () => {
        switch (this.user.userType) {
            case "PROVIDER":
                return (
                    <div id="meet"
                        style={
                            {
                                width: "100%",
                                height: "100%"
                            }
                        }></div>
                );
            default:
                return (
                    <div style={
                        {
                            width: "100vw",
                            height: "100vh"
                        }
                    }>
                        <div id="meet"
                            style={
                                {
                                    width: "100%",
                                    height: "100%"
                                }
                            }></div>
                    </div>
                );
        }
    };

    render() {
        return this.renderConf();
    }
}
