import React from 'react';
import { Progress } from 'antd';
import zxcvbn from 'zxcvbn';

export class ProgressBar extends React.Component {

    testPassword = () => {
        let streng = zxcvbn(this.props.pass).score;
        this.props.updateStreng(streng);
        return streng * 33.33;
    }

    render() {
        return(
            <Progress
            strokeColor={{
                from: '#52C8FA',
                to: '#87d068',
            }}
            showInfo={false}
            percent={this.testPassword()}
            status="active"
            />
        );
    }
}