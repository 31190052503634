import api from "./api";
import storage from "../utils/Storage";
import { message } from "antd";
require("dotenv/config");

class UserAPI {
    /**
     * access the application with your credentials.
     *
     * @param {string} email
     * @param {string} password
     *
     * @returns {Object}
     */
    login = async (email, password) => {
        try {
            let response = await api.post(
                "/api/login",
                {
                    email: email,
                    password: password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            storage.setUser(response.data);
            storage.setAuth(true);

            return response.data;
        } catch (error) {
            message.error("Email ou senha inválida.");
            storage.setAuth(false);
        }
    };


    loginWithToken = async (token) => {
      try {
          let response = await api.post(
              "/api/loginWithToken",
              {
                  token: token,
              },
              {
                  headers: {
                      "Content-Type": "application/json",
                  },
              }
          );

          storage.setUser(response.data);
          storage.setAuth(true);

          return response.data;
      } catch (error) {
          message.error("Email ou senha inválida.");
          storage.setAuth(false);
      }
  };

    /**
     * Register the user in the application.
     *
     * @param {object} user
     *
     * @returns {object}
     */
    register = async (user) => {
        try {
            const response = await api.post(
                "/api/users",
                {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone,
                    cpf: user.cpf,
                    doc: user.doc,
                    userType: "REQUESTER",
                    company: "695e013a-28e6-484e-9df2-069923bdc7f6",
                    email: user.email,
                    password: user.password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            message.success("Usuário cadastrado com sucesso!");
            return { error: false };
        } catch (error) {
            message.error("Não foi possível realizar o cadastro.");
            return { error: true, data: error.response.data };
        }
    };

    /**
     * exit the application.
     *
     * @returns {Object}
     */
    logout = async () => {
        try {
            let response = await api.post("/api/logout", null, {
                headers: {
                    Authorization: "Bearer " + storage.getUser().token,
                },
            });

            console.log("# logout #");

            storage.removeAll();
        } catch {
            storage.removeAll();
        }
    };

    /**
     * signal to the server that is active.
     * 
     * @param {Object} history
     */
    imAlive = async (history) => {
        console.log("history: ", history);
        try {
            let response = await api.post("/api/imAlive", null, {
                headers: {
                    Authorization: "Bearer " + storage.getUser().token,
                },
            });

            console.log("imAlive");
            setTimeout(() => {
                storage.getAuth() && this.imAlive(history);
            }, 3000);
        } catch (error) {
            if (error.response.status == 502) {
                console.log("imAlive: ", error.response.status);
            }
            else if (error.response.status == 401) {
                storage.setAuth(false);
                storage.getUser().user.userType == "PROVIDER"
                    && history.push("/login/errorAuth");
            }

            console.log(error, error.response);
            setTimeout(() => {
                storage.getAuth() && this.imAlive(history);
            }, 3000);
        }
    };

    /**
     * get all providers.
     *
     * @returns {Object}
     */
    getProviders = async () => {
        // if the user has logged out.
        if (!storage.getUser()) {
            return { error: true };
        }

        try {
            let response = await api.get("/api/getProviders", {
                headers: {
                    Authorization: "Bearer " + storage.getUser().token,
                },
            });

            storage.setProviders(response.data);
            return { error: false };
        } catch (error) {
            if (error.response.status == 401) {
                console.log("getProviders: ", error.response.status);
                storage.setAuth(false);
            }
            return { error: true };
        }
    };

    /**
     *
     * @returns {Object}
     */
    UpdateAvatar = async (formData) => {
        console.log("formData: ", formData);

        try {
            let response = await api.post("/api/updateUser", formData, {
                headers: {
                    Authorization: "Bearer " + storage.getUser().token,
                },
            });

            let userData = storage.getUser();
            userData.user.avatar_url = response.data.user.avatar_url;
            storage.setUser(userData);
            window.location.reload(false);
        } catch (error) {
            console.log("Falha ao enviar arquivo.");
        }
    };
}

export default new UserAPI();
