import React from "react";
import { Form, Input, notification } from "antd";
import { UserAPI } from "../../services/";
import { Icon } from "@iconify/react";
import envelopeO from "@iconify/icons-fa/envelope-o";
import storage from "../../utils/Storage";
import { Proconlogo } from "../../assets";
import "./Login.css";
require("dotenv/config");

class Login extends React.Component {
    componentDidMount = () => {
        if (this.props.match.params.page) {
            notification.error({
                message: "Token de acesso expirado.",
                description: "efetue o login para continuar.",
            });
        }
        
        const searchParams = new URLSearchParams(this.props.location.search)
        const libras = searchParams.get('libras');
        const token = searchParams.get('token');
        const callback = searchParams.get('callback');

        if (token) {
            this.loginWithToken(token, libras == 'true', callback)
        }
    };

    loginWithToken = async (token, libras, callback) => {
      let response = await UserAPI.loginWithToken(token);

      if (!storage.getAuth()) {
        return;
      }

      if (response.user.userType === "PROVIDER") {
        return this.props.history.push({ pathname: "/consultant", state: { libras, callback }});
      } else {
          return this.props.history.push({ pathname: "/request", state: { libras, callback }});
      }
    }


    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            let response = await UserAPI.login(values.email, values.password);

            if (!storage.getAuth()) {
                return;
            }

            if (response.user.userType === "PROVIDER") {
                return this.props.history.push("/consultant");
            } else {
                return this.props.history.push("/request");
            }
        });
    };

    handlerRegister = (e) => {
        e.preventDefault();
        this.props.history.push("/register");
    };

    handleRequest = (e) => {
        e.preventDefault();
        this.props.history.push("/request");
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="Login">
                <div className="header">
                    <div className="header-email">
                        <Icon icon={envelopeO} />
                        <span className="email">
                            {process.env.REACT_APP_EMAIL}
                        </span>
                    </div>
                    <div className="header-version">Versão: 13b8yui</div>
                </div>

                <div className="login-intro">
                    <Proconlogo />
                    <h1>Plantão</h1>
                </div>
                <Form onSubmit={this.handleSubmit} id="loginForm">
                    <Form.Item>
                        {getFieldDecorator("email", {
                            rules: [
                                {
                                    required: true,
                                    message: "Por favor insira seu email!",
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                className="inputLogin"
                                placeholder="Email"
                                autoFocus
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator("password", {
                            rules: [
                                {
                                    required: true,
                                    message: "Por favor insira sua senha!",
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                className="inputLogin"
                                type="password"
                                placeholder="Senha"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <button className="loginFormButton">Entrar</button>
                        <div className="request">
                            <a href="" onClick={this.handleRequest}>
                                Esqueceu sua senha?
                            </a>
                        </div>
                        <div className="signup">
                            <a href="" onClick={this.handlerRegister}>
                                Cadastre-se
                            </a>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const WrappedLogin = Form.create({ name: "normal_login" })(Login);

export default WrappedLogin;
