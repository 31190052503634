class Storage {
    constructor() {
        this._user = JSON.parse(localStorage.getItem("user"));
        this._room = JSON.parse(localStorage.getItem("room"));
        this._providers = JSON.parse(localStorage.getItem("providers"));
        this._auth = Boolean(localStorage.getItem("authenticated"));
    }

    getUser = () => {
        return this._user;
    };

    getRoom = () => {
        return this._room;
    };

    getProviders = () => {
        return this._providers;
    };

    getAuth = () => {
        return this._auth;
    };

    setUser = (user) => {
        this._user = user;
        localStorage.setItem("user", JSON.stringify(user));
        this.setAuth(true);
    };

    setRoom = (room) => {
        this._room = room;
        localStorage.setItem("room", JSON.stringify(room));
        this.setAuth(true);
    };

    setProviders = (providers) => {
        this._providers = providers;
        localStorage.setItem("providers", JSON.stringify(providers));
        this.setAuth(true);
    };

    setAuth = (auth) => {
        this._auth = auth;
        localStorage.setItem("authenticated", auth);
    };

    removeRoom = () => {
        this._room = "";
        localStorage.removeItem("room");
    };

    removeAll = () => {
        this._user = "";
        this._auth = "";
        this._providers = "";
        this._room = "";
        localStorage.clear();
    };
}

export default new Storage();

/* USER DATA

{
    "message": "Login Authorized",
    "user": {
        "uuid": "68facf24-f1eb-4d1d-bbf4-1bedbb984a91",
        "firstName": "Clístenes",
        "lastName": "Onassis",
        "phone": "83982847868",
        "cpf": "70192289408",
        "doc": "1891",
        "email": "co@gmail.com",
        "userType": "PROVIDER",
        "status": "PAUSED",
        "companyId": "36e9bbdc-23ec-4548-850f-09b1828b4f06",
        "token_jwt": null,
        "password_hash": "$2a$08$xzMwm62m4j2c3mvQv7RBmuGtKOcM7SXUR/ad7DiGBpJ4qvc1hPE/e",
        "createdAt": "2020-05-02T18:23:32.740Z",
        "updatedAt": "2020-05-02T18:24:17.806Z",
        "company_id": "36e9bbdc-23ec-4548-850f-09b1828b4f06"
    },
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2OGZhY2YyNC1mMWViLTRkMWQtYmJmNC0xYmVkYmI5ODRhOTEiLCJpYXQiOjE1ODg0NDQwODQsImV4cCI6MTU4ODQ1MTI4NH0.pm5sCC8BkQozkfrEm-89zwCFBVCcCfVWM8WxKIQLT84"
}

*/

/* PROVIDERS
{
    "message": "All providers users",
    "users": {
        "uuid": "0b535f34-9cb2-4fc5-8a83-6b0a4bdbb296",
        "firstName": "Leoberto",
        "lastName": "Soares",
        "phone": "83982052591",
        "cpf": "00000100100",
        "doc": "0110",
        "email": "leobertosoares@lavid.ufpb.br",
        "userType": "PROVIDER",
        "status": "PAUSED",
        "avatar_url": null
    }
}
*/
