import React from "react";
import "./YourAvatar.css";
import { Icon } from "@iconify/react";
import userCircle from "@iconify/icons-fa-solid/user-circle";
import Storage from "../../utils/Storage";
import UploadAvatar from "../avatar/UploadAvatar";

export default function YourAvatar(props) {
    let format = props.format;
    let user = Storage.getUser().user;

    const renderAvatar = () => {
        switch (format) {
            case "horizontal":
                return (
                    <div id="yourAvatarContainer" className="horizontal">
                        <div className="yourAvatar">
                            {user.avatar_url ? (
                                <div
                                    className="avatar img"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            `${process.env.REACT_APP_BACKEND_URL}/public/${user.avatar_url}` +
                                            ")",
                                    }}
                                ></div>
                            ) : (
                                <Icon icon={userCircle} className="avatar" />
                            )}
                            <UploadAvatar />
                        </div>
                        <div>
                            <h2>{user.firstName + " " + user.lastName}</h2>
                            <h3>Solicitante</h3>
                        </div>
                    </div>
                );
            default:
                return (
                    <div id="yourAvatarContainer" className="vertical">
                        <div className="yourAvatar">
                            {user.avatar_url ? (
                                <img
                                    src={`${process.env.REACT_APP_BACKEND_URL}/public/${user.avatar_url}`}
                                    className="avatar"
                                />
                            ) : (
                                <Icon icon={userCircle} className="avatar" />
                            )}
                            <UploadAvatar />
                        </div>
                        <h2>{user.firstName + " " + user.lastName}</h2>
                        <h3>Teleconsultor</h3>
                    </div>
                );
        }
    };

    return renderAvatar();
}
