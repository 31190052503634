import React from "react";
import { Popover } from "antd";
import {RoomAPI, UserAPI} from "../../services";

export default class CancelAndOk extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };

        this.node = this.props.node;
        this.text = this.props.text;
    }

    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handlerOk = async () => {
        await RoomAPI.closeRoom();
        await UserAPI.logout();
        this.props.history.push("/");
    };

    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };

    render() {
        const body = (
            <div className="popoverBody" style={{display: "flex", justifyContent: "space-between"}}>
                <a onClick={this.hide} style={{"color": "red"}} >Cancelar</a>
                <a onClick={this.handlerOk}>Sair</a>
            </div>
        );


        return (
            <Popover
                content={body}
                title={this.text}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
            >
                {this.node}
            </Popover>
        );
    }
}
