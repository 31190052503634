import React from "react";

import "./Consultant.css";
import YourAvatar from "../../components/avatar/YourAvatar";
import { Layout } from "antd";
import RoomAPI from "../../services/RoomAPI";
import StartConf from "../room/StartConf";
import CancelAndOk from "../../components/popover/CancelAndOk";
import MobileError from "../mobile/MobileError";
import MobileRedirect from "../../utils/MobileRedirect";
import storage from "../../utils/Storage";
import { UserAPI } from "../../services";

const { Sider } = Layout;

export default class Solicitante extends React.Component {
    constructor(props) {
        super(props);

        const { libras, callback } = (props.location && props.location.state) || {};

        this.state = {
            status: "PAUSED",
            libras,
            callback
        };

    }

    componentDidMount = () => {
        this.authenticated();
        UserAPI.imAlive(this.props.history);
    };

    authenticated = () => {
        if (storage.getAuth()) {
            return;
        }
        storage.removeAll();
        this.props.history.push("/login/consultant");
    };

    handlerLogout = async () => {
        await UserAPI.logout();
        this.props.history.push("/");
    };

    createConference = async () => {
        await RoomAPI.createRoom();
        this.authenticated();
        this.setState({ status: "AVAILABLE" });
    };

    changeState = () => {
        this.setState({ status: "PAUSED" });
    };

    RenderBody = () => {
        switch (this.state.status) {
            case "PAUSED":
                return (
                    <div id="content">
                        <p>Entre na sala para iniciar a consultoria.</p>
                        <button onClick={this.createConference}>Entrar</button>
                    </div>
                );
            default:
                return <StartConf changeState={this.changeState} libras={this.state.libras} callback={this.state.callback} />;
        }
    };

    render() {

        if (MobileRedirect.isMobile()) {
            return <MobileError history={this.props.history} />;
        }

        return (
            <Layout id="consultantContainer">
                <Sider className="siderMenu" width={null}>
                    <YourAvatar format="vertical" />
                    <div id="sidebarFooter">
                        {this.state.status == "PAUSED" ?
                            <button onClick={this.handlerLogout}>Sair</button>
                            :
                            <CancelAndOk
                                node={<button>Sair</button>}
                                history={this.props.history}
                                text="Você realmente deseja sair?"
                            />
                        }
                        {/* <div className="v4h">v4h.cloud</div> */}
                        <img src="https://minio.homolog.v4h.cloud/procon/procon-jp-logo.png" style={{"maxWidth": "100px"}}/>
                    </div>
                </Sider>

                <this.RenderBody />
            </Layout>
        );
    }
}
