import React from "react";
import ReactDOM from "react-dom";
import Inputmask from "inputmask";
import { ProgressBar } from "../../components/common/ProgressBar";
import InputMask from "../../components/common/InputMask/inputMask";
import "antd/dist/antd.css";
import "./Register.css";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import { UserAPI } from "../../services";

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            cpf: "",
            email: "",
            crm: "",
            phone: "",
            pass: "",
            confirmPass: "",
            passStreng: 0,
            confirmDirty: false,
        };
    }

    handlerClickBackButton = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    };

    validateName = (rule, value, callback) => {
        let name = value.split(" ");
        name = name.filter((value) => { return value != "" })
        console.log(name);
        if (value && name.length < 2) {
            callback("Por favor insira seu nome completo!")
        } else {
            callback();
        }
    };

    handleConfirmBlur = (e) => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue("password")) {
            callback("As senhas digitadas são inconsistentes!");
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(["checkpassword"], { force: false });
        }
        callback();
    };

    validateCpf = (rule, value, callback) => {
        const { form } = this.props;
        let unmask = "";
        for (let i = 0; i < value.length; i++) {
            if (value[i].match(/(\d{1})/)) {
                unmask += value[i];
            }
        }

        if (unmask.length == 11 && !this.checkCpf(unmask)) {
            callback("Por favor Insira um cpf válido");
        } else {
            callback();
        }
    };

    setEmailError = () => {
        this.props.form.setFields({
            email: {
                value: this.state.email,
                errors: [new Error("O email já está em uso!")],
            }
        });
    }

    setCpfError = () => {
        this.props.form.setFields({
            cpf: {
                value: this.state.cpf,
                errors: [new Error("O CPF já está em uso!")],
            }
        });
    }

    setDotError = () => {
        this.props.form.setFields({
            doc: {
                value: this.state.crm,
                errors: [new Error("O CRM já está em uso!")],
            }
        });
    }

    checkCpf = (strCPF) => {
        let sum, rest, i;
        sum = 0;
        if (strCPF == "00000000000") return false;

        for (i = 1; i <= 9; i++)
            sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        rest = (sum * 10) % 11;

        if (rest == 10 || rest == 11) rest = 0;
        if (rest != parseInt(strCPF.substring(9, 10))) return false;

        sum = 0;
        for (i = 1; i <= 10; i++)
            sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        rest = (sum * 10) % 11;

        if (rest == 10 || rest == 11) rest = 0;
        if (rest != parseInt(strCPF.substring(10, 11))) return false;
        return true;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            console.log(err);
            if (!err) {
                let name = values.name.split(" ");
                values.firstName = name[0];
                values.lastName = name[1];
                console.log("Received values of form: ", values);
                const response = await UserAPI.register(values);
                console.log(response);
                if (response.error) {
                    console.log("tem erro")

                    try {
                        response.data.type.forEach((element) => {
                            if (element == "email") {
                                this.setEmailError();
                            } else if (element == "cpf") {
                                this.setCpfError();
                            } else if (element == "doc") {
                                this.setDotError();
                            }
                        })
                    } catch {
                        console.log("outro erro");
                    }
                    return;
                }
                console.log("tudo certo");
                e.preventDefault();
                this.props.history.push("/");
            }
        });
    };

    passwordStreng = (streng) => {
        this.state.passStreng = streng;
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { pass } = this.state;
        console.log(this.state);
        return (
            <div className="pageRegisterContainer">
                <div className="backButtonContainer" onClick={this.handlerClickBackButton}>
                    <svg className="backButtonSvg">
                        <rect className="backButtonRect" />
                    </svg>
                    <div className="backButtonText">Voltar</div>
                </div>
                <div className="registerContainer">
                    <div className="signup">Cadastro</div>
                    <Form onSubmit={this.handleSubmit} id="registerForm">
                        <Form.Item>
                            {getFieldDecorator("name", {
                                initialValue: "",
                                rules: [
                                    {
                                        required: true,
                                        message:
                                            "Por favor insira seu nome completo!",
                                    },
                                    {
                                        validator: this.validateName
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    className="inputRegister"
                                    onChange={(e) =>
                                        this.setState({ name: e.target.value })
                                    }
                                    prefix={
                                        <Icon
                                            type="user"
                                            style={{ color: "rgb(153, 153, 153)" }}
                                        />
                                    }
                                    placeholder="Nome Completo"
                                    autoFocus
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("cpf", {
                                initialValue: "",
                                rules: [
                                    {
                                        required: true,
                                        message: "Por favor insira seu cpf!",
                                    },
                                    { validator: this.validateCpf },
                                ],
                            })(
                                <InputMask
                                    size="large"
                                    mask="999.999.999-99"
                                    className="inputRegister"
                                    onChange={(e) =>
                                        this.setState({ cpf: e.target.value })
                                    }
                                    prefix={
                                        <Icon
                                            type="user"
                                            style={{ color: "rgb(153, 153, 153)" }}
                                        />
                                    }
                                    placeholder="CPF"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        type: "email",
                                        message:
                                            "Por favor insira um email válido!!",
                                    },
                                    {
                                        required: true,
                                        message: "Por favor insira seu email!",
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    className="inputRegister"
                                    onChange={(e) =>
                                        this.setState({ email: e.target.value })
                                    }
                                    prefix={
                                        <Icon
                                            type="mail"
                                            style={{ color: "rgb(153, 153, 153)" }}
                                        />
                                    }
                                    placeholder="Email"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("doc", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Por favor insira seu CRM!",
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    className="inputRegister"
                                    onChange={(e) =>
                                        this.setState({ crm: e.target.value })
                                    }
                                    prefix={
                                        <Icon
                                            type="user"
                                            style={{ color: "rgb(153, 153, 153)" }}
                                        />
                                    }
                                    placeholder="CRM"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("phone", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Por favor insira seu telefone!",
                                    },
                                ],
                            })(
                                <InputMask
                                    id="phone"
                                    size="large"
                                    mask="(99) 99999-9999"
                                    className="inputRegister"
                                    onChange={(e) =>
                                        this.setState({ phone: e.target.value })
                                    }
                                    prefix={
                                        <Icon
                                            type="phone"
                                            style={{ color: "rgb(153, 153, 153)" }}
                                        />
                                    }
                                    placeholder="Celular"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("password", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Por favor insira sua senha!",
                                    },
                                    { validator: this.validateToNextPassword },
                                ],
                            })(
                                <Input
                                    size="large"
                                    className="inputRegister"
                                    type="password"
                                    onChange={(e) =>
                                        this.setState({ pass: e.target.value })
                                    }
                                    prefix={
                                        <Icon
                                            type="lock"
                                            style={{ color: "rgb(153, 153, 153)" }}
                                        />
                                    }
                                    placeholder="Senha"
                                />
                            )}
                        </Form.Item>
                        <div style={{ color: "#999" }}>
                            Sua senha deve ter no mínimo 6 caracteres.
                    </div>
                        <div className="progressBarContainer">
                            <ProgressBar
                                updateStreng={this.passwordStreng}
                                pass={pass}
                            />
                        </div>
                        <Form.Item>
                            {getFieldDecorator("checkpassword", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Por favor confirme sua senha!",
                                    },
                                    { validator: this.compareToFirstPassword },
                                ],
                            })(
                                <Input
                                    size="large"
                                    className="inputRegister"
                                    type="password"
                                    onChange={(e) =>
                                        this.setState({
                                            confirmPass: e.target.value,
                                        })
                                    }
                                    onBlur={this.handleConfirmBlur}
                                    prefix={
                                        <Icon
                                            type="lock"
                                            style={{ color: "rgb(153, 153, 153)" }}
                                        />
                                    }
                                    placeholder="Confirme sua Senha"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                className="registerFormButton"
                            >
                                Cadastre-se
                        </Button>
                            <div className="formBar"></div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

const WrappedRegisterForm = Form.create({ name: "register" })(RegisterForm);

export default WrappedRegisterForm;
