import React from 'react';
import 'antd/dist/antd.css';
import { Form, Input } from 'antd';
import { V4Hlogo } from "../../assets/index";
import "./PasswordRequest.css";

class PasswordRequest extends React.Component {    
    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="RequestPass">
                <div className="header">
                    <V4Hlogo/>
                    <span>Esqueceu sua senha? Sem problemas.</span>
                    <span>Enviaremos uma solicitação para você criar uma nova.</span>
                </div>
                <Form id="requester">
                    <Form.Item>
                    {getFieldDecorator('requestpass',{
                        rules: [
                            {
                               required: true,
                               message: 'Por favor, insira seu email para solicitação de nova senha.',
                            }
                        ],
                    })(<Input
                            size="large"
                            className="email" 
                            placeholder="Por favor, insira seu email" />)}
                    </Form.Item>
                    <Form.Item>
                        <button className="requestButton" type="primary">Enviar</button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const WrapRequest = Form.create({ name:"password_request"})(PasswordRequest);

export default WrapRequest;

