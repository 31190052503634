import React from "react";

import "./Requester.css";
import { Button, Modal, Spin } from "antd";
import { UserAPI } from "../../services";
import YourAvatar from "../../components/avatar/YourAvatar";
import List from "../../components/ConsultantList/List";
import storage from "../../utils/Storage";
import UploadAvatar from "../../components/avatar/UploadAvatar";

export default class Requester extends React.Component {
  constructor(props) {
    super(props);

    const { libras, callback } = (props.location && props.location.state) || {};

    this.state = {
      waitingRoom: true,
      libras,
      callback
    };
  }

  componentDidMount = async () => {
    UserAPI.imAlive();
    this.authenticated();
  };

  authenticated = () => {
    if (storage.getAuth()) {
      return;
    }
    storage.removeAll();
    this.props.history.push("/login/request");
  };

  handlerLogout = async () => {
    await UserAPI.logout();
    this.props.history.push("/");
  };

  onJoinWaitingRoom = async () => {
    this.setState({ waitingRoom: true });
  }

  onLeftWaitingRoom = async () => {
    this.setState({ waitingRoom: false });
  }

  render() {
    return (
      <div id="requesterBody">
        <div className="header">
          <span className="appName">Procon - Plantão</span>
          <Button size="small" className="logout"
            onClick={
              this.handlerLogout
            }>
            Sair
          </Button>
        </div>

        <Modal 
          centered 
          closable={false}
          keyboard={false}
          visible={this.state.waitingRoom}
          footer={null}
          >
          <div id="modal">
            <p>Você está na sala de espera, assim que um consultor estiver disponível você será redicionado para conferência.</p>
            <Spin id="spin" size="large" />
            <Button id="button-close" type="primary" danger={true} onClick={this.onLeftWaitingRoom}>Sair da lista espera</Button>
          </div>
      </Modal>

        <div className="applicantContainer">
          <YourAvatar format="horizontal" />
        </div>

        <div className="titleTable">
          <span>Lista de <strong>Teleconsultores</strong></span>
          <Button onClick={this.onJoinWaitingRoom}>Entrar na sala de espera</Button>
        </div>

        <div className="tableContainer">
          <List history={this.props.history} waitingRoomEnabled={this.state.waitingRoom} libras={this.state.libras} callback={this.state.callback}/>
        </div>
      </div>
    );
  }
}
