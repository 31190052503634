class MobileRedirect {

    /**
     * Check which device is accessing the page.
     */
    isMobile = () => {
        var ua = navigator.userAgent.toLowerCase();
        var uMobile = [
            "iphone",
            "ipod",
            "iPad",
            "Opera Mini",
            "BlackBerry",
            "windows phone",
            "android",
            "iemobile 8",
        ];

        // go through all the items, making sure it is mobile.
        for (var i = 0; i <= uMobile.length; i++) {
            if (ua.indexOf(uMobile[i]) != -1) {
                return true;
            }
        }

        return false;
    };

    start = (history, token, sessionId) => {
        if (this.isMobile()) {
            return history.push("/mobile", {
                token: token,
                sessionId: sessionId
            });
        }
    }

}


export default new MobileRedirect();
