import React, { Component } from "react";
import "./UploadAvatar.css";
import { UserAPI } from "../../services/";

export default class UploadAvatar extends Component {
    fileUploadHandler = async (data) => {
        if (!data.target.files[0]) {
            return;
        }
        console.log(data.target.files[0]);
        console.log(data.target.files[0].name);

        const formData = new FormData();
        formData.append("filename", data.target.files[0]);

        await UserAPI.UpdateAvatar(formData);
    };

    render() {
        return (
            <div className="uploadButton avatar">
                <label className="imgUpload">
                    <input
                        type="file"
                        onChange={this.fileUploadHandler}
                        accept=".png, .jpg, .jpeg"
                    />
                    Enviar foto
                </label>
            </div>
        );
    }
}
